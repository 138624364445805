import React, { useEffect, useState } from 'react';
import Table from './team/TeamTable';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { League } from './model/League';
import { Player } from './model/Player';
import { Team } from './model/Team';

const endpoint =
  'https://f3v080ez1k.execute-api.us-west-2.amazonaws.com/dev/3487f254-a1dd-484a-bf81-d11677408e9f?year=2023';

const fetchData = async () => {
  const data = await fetch(endpoint).then(res => res.json());
  return data;
};

const sumPoints = (players: Player[], mvp: Player) => {
  let totalScore = 0;
  players.forEach(player => {
    if (player.id === mvp.id) {
      totalScore += 1.5 * player.points;
    } else {
      totalScore += player.points;
    }
  })

  return totalScore;
}

const convertToModel = (json: any): League => {
  const players: { [id: string]: Player } = {};
  const teams: { [id: string]: Team } = {};
  json.players.forEach(
    (player: any) =>
      (players[player.id] = {
        name: player.name,
        id: player.id,
        points: Number.parseFloat(player.points),
        position: player.position,
      })
  );

  json.teams.forEach(
    (team: any) =>
      (teams[team.id] = {
        name: team.name,
        id: team.id,
        players: team.players.map((playerId: string) => players[playerId]),
        mvp: players[team.mvp],
        score: sumPoints(team.players.map((playerId: string) => players[playerId]), players[team.mvp])
      })
  );

  return {
    id: json.league.id as string,
    name: json.league.name as string,
    teams: Object.values(teams).sort((a, b) => a.score >= b.score ? -1 : 1),
  };
};

let init = false;

function App() {
  const [league, setLeague] = useState<League | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!init) {
      fetchData().then(data => {
        const league = convertToModel(data);
        setLeague(league);
        setLoading(false);
      });
    }
    init = true;
  }, []);

  return (
    <>
      <Container>
        {loading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
          >
            <Grid item xs={3}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <>
            <Box textAlign={'center'} mb={1}>
              <Typography variant="overline" fontWeight={'bold'} fontSize={14}>
                {league?.name}
              </Typography>
            </Box>
            <Grid container spacing={1}>
              {league?.teams.map(team => (
                <>
                  <Grid item xs={12} key={team.id}>
                    <Box mb={1}>
                      <Table team={team} />
                    </Box>
                  </Grid>
                </>
              ))}
            </Grid>
            <Box textAlign={'center'} mt={3}>
              <Link
                variant="caption"
                href={'https://www.flaticon.com/free-icons/turkey'}
                underline="hover"
              >
                Icon by freepik
              </Link>
              <Link
                marginLeft={3}
                variant="caption"
                href={
                  'https://fantasydata.com/nfl/fantasy-football-leaders?season=2022&seasontype=1&scope=2&subscope=1&scoringsystem=7&startweek=12&endweek=12&aggregatescope=1&range=3'
                }
                underline="hover"
              >
                Scoring by fantasydata
              </Link>
            </Box>
          </>
        )}
      </Container>
    </>
  );
}

export default App;
